import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
} from '@repo/ui-shadcn';
import { z } from 'zod';
import LoginLogo from '../assets/login.png';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { APIService } from '@repo/api-services';
import type { ActionFunctionArgs, TypedResponse } from '@remix-run/node';
import { GenericActionResponse } from '../types/actions';
import { useCallback, useEffect, useState } from 'react';
import { useActionData, useSubmit } from '@remix-run/react';
import { redirect } from '@remix-run/node';
import { toErrorJson } from '../lib/error';
import { tokenCookie, userCookie } from '../storage/oms-cookie.server';
import { LoginRequest } from '@repo/api-services/domains/customer/requests/auth';
import { toast } from '@repo/ui-shadcn/components/ui/toast/use-toast';

export const meta = () => {
  return [{ title: 'OMS' }, { name: 'description', content: '' }];
};

// Initialize Zod form
const formSchema = z.object({
  clientSlug: z.string().min(1, 'Client slug is required'),
  email: z.string().min(1, 'Email is required').email('Invalid email format'),
  password: z.string().min(1, 'Password is required'),
});

export default function LoginPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionData = useActionData<typeof action>();

  const submit = useSubmit();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      clientSlug: '',
      email: '',
      password: '',
    },
  });

  const onSubmit = useCallback(
    (values: z.infer<typeof formSchema>) => {
      setIsSubmitting(true);

      // define the payload
      const payload: LoginRequest = {
        clientSlug: values.clientSlug,
        email: values.email,
        password: values.password,
      };
      const formData = new FormData();
      formData.append('loginPayload', JSON.stringify(payload));

      try {
        submit(formData, { method: 'post', encType: 'multipart/form-data' });
      } finally {
        setTimeout(() => {
          setIsSubmitting(false);
        }, 300);
      }
    },
    [submit],
  );

  useEffect(() => {
    const errorMessage = actionData?.error?.data?.error;
    if (actionData && !actionData.success && errorMessage) {
      toast({
        title: 'Failed to login',
        description: 'Please try again!',
        variant: 'destructive',
      });
    }
  }, [actionData]);

  return (
    <div className="bg-primary flex min-h-screen w-full">
      {/* Left side - Login Form */}
      <div className="grid h-screen w-full grid-cols-12 place-items-center p-6">
        <div className="col-span-10 col-start-2  lg:col-span-8 lg:col-start-3">
          <div className="grid lg:grid-cols-2">
            <Card className="rounded-2xl bg-white p-4 lg:rounded-l-[32px] lg:rounded-r-none lg:p-8 xl:p-16">
              <CardHeader className="space-y-2 text-center">
                <CardTitle className="text-3xl font-bold text-blue-600">Welcome Back!</CardTitle>
                <p className="text-muted-foreground">Login to your customer account</p>
              </CardHeader>
              <CardContent className="space-y-4">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
                    <FormField
                      control={form.control}
                      name="clientSlug"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Client slug</FormLabel>
                          <FormControl>
                            <Input placeholder="Enter your client slug" {...field} required />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input placeholder="Enter your email" type="email" {...field} required />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Password</FormLabel>
                          <FormControl>
                            <Input placeholder="Enter your password" type="password" {...field} required />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <div className="py-8">
                      <Button variant={'default'} className="w-full" type="submit" disabled={isSubmitting}>
                        Login
                      </Button>
                    </div>
                  </form>
                </Form>
              </CardContent>
            </Card>
            <div className="hidden lg:block">
              <img src={LoginLogo} alt="login logo" className="size-full rounded-r-[32px] object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const action = async ({ request }: ActionFunctionArgs): Promise<TypedResponse<GenericActionResponse>> => {
  const formData = await request.formData();
  const payload = JSON.parse(formData?.get('loginPayload') as string) as LoginRequest;

  const service = new APIService.customer();

  try {
    const response = await service.auth.postLogin(payload);
    const token = response.token;
    const tokenHeader = await tokenCookie.serialize(token);
    const userHeader = await userCookie.serialize({ name: response.customerName, email: response.email, slug: response.client?.slug });

    const headers = new Headers();
    headers.append('Set-Cookie', tokenHeader);
    headers.append('Set-Cookie', userHeader);

    return redirect('/', {
      headers: headers,
    });
  } catch (error: unknown) {
    return toErrorJson(error);
  }
};
